// ******************************************************************* //
//  _______       ___      .__   __.   _______  _______ .______        //
// |       \     /   \     |  \ |  |  /  _____||   ____||   _  \       //
// |  .--.  |   /  ^  \    |   \|  | |  |  __  |  |__   |  |_)  |      //
// |  |  |  |  /  /_\  \   |  . `  | |  | |_ | |   __|  |      /       //
// |  '--'  | /  _____  \  |  |\   | |  |__| | |  |____ |  |\  \----.  //
// |_______/ /__/     \__\ |__| \__|  \______| |_______|| _| `._____|  //
// ******************************************************************* //

// DO NOT modify this file. It's generated by the "colors_to_sass.js" script. 
// If you need to make changes to the palette or the functions, 
// please look at "colors_to_sass.js" and how it works witth the "_colors.scss.tpl" file



// Edmodo Design Guide Colors (Spring 2019)
// Alternative approach to plain color constants
// Define a map and associated function to retrieve vals:
// ex. syntax: color(gray, 30);
$palettes: (
  white: (
    0: #fff
  ),
  black: (
    0: #1c1e27,
    3: #333333,
    5: #2D2D2D
  ),
  gray: (
    3: #f4f4f7,
    5: #ebebef,
    6: #e6eeff,
    7: #e1e1e7,
    10: #ccced7,
    20: #abaeba,
    30: #8b8e9f,
    40: #73778b,
    50: #5c6178,
    60: #4f5468,
    70: #3e4253,
    80: #2e313e,
    90: #1c1e27,
    95: #7f7f7f,
    96: #e8e9f2,
    99: #999999
  ),
  blue: (
    5: #e9ebff,
    10: #c7cdff,
    20: #a0aeff,
    30: #738dff,
    40: #4b71ff,
    50: #0055ff,
    60: #004cf3,
    70: #0040e6,
    80: #0034db,
    90: #001acb,
    normal: #5F8BFF,
    dark: #2B3674,
    light: #56C5FF
  ),
  coral: (
    5: #ffeff1,
    10: #ffd6d9,
    20: #f8a9a5,
    30: #f28882,
    40: #ff6f61,
    50: #ff6347,
    60: #f75c48,
    70: #e45241,
    80: #d74c3b,
    90: #c8422f
  ),
  cyan: (
    5: #dff6ff,
    10: #aee7fe,
    20: #74d7ff,
    30: #24c7ff,
    40: #00bbff,
    50: #00aeff,
    60: #009ff1,
    70: #008cdd,
    80: #007bc9,
    90: #005ba7
  ),
  gold: (
    5: #fff8e1,
    10: #ffecb3,
    20: #ffe082,
    25: #fef8c1,
    30: #ffd54f,
    40: #ffca28,
    50: #ffc107,
    60: #ffb300,
    70: #ffa000,
    80: #ff8f00,
    90: #ff6f00,
    95: #fab400
  ),
  green: (
    5: #e3fdeb,
    10: #bcf8cc,
    20: #8af4ab,
    30: #3df085,
    40: #00ea65,
    50: #00e14c,
    60: #00d041,
    70: #00bc33,
    80: #00aa27,
    90: #00890e,
    light: #3ee0b9,
  ),
  indigo: (
    5: #e8eaf6,
    10: #c5cae9,
    20: #9fa8da,
    30: #7986cb,
    40: #5c6bc0,
    50: #3f51b5,
    60: #3949ab,
    70: #303f9f,
    80: #283593,
    90: #1a237e
  ),
  olive: (
    5: #f6fbe7,
    10: #e8f3c3,
    20: #d9ec9d,
    30: #cbe476,
    40: #c0de59,
    50: #b7d83d,
    60: #acc636,
    70: #9cb02e,
    80: #8d9926,
    90: #737317
  ),
  orange: (
    5: #fde9e7,
    10: #ffccbd,
    20: #ffab92,
    30: #ff8966,
    40: #ff6e45,
    50: #ff5224,
    60: #ff4c20,
    70: #f1451b,
    80: #e33d16,
    90: #ca2f0b
  ),
  pink: (
    5: #fae3ff,
    10: #f1b9fe,
    20: #e787fe,
    30: #dc49ff,
    40: #d200ff,
    50: #c200f3,
    60: #af00ee,
    70: #9400e9,
    80: #7b00e3,
    90: #6b00d8
  ),
  purple: (
    5: #efe9fd,
    10: #d5cafa,
    20: #b9a6f7,
    30: #9b81f5,
    40: #8264f2,
    50: #6748ee,
    60: #5a43e7,
    70: #483bde,
    80: #3435d7,
    90: #331cdc,
    normal: #A477FF,
    vibrant: #7B40F2,
    light: #F6F5FF
  ),
  red: (
    5: #ffe9ee,
    10: #ffc9d1,
    20: #fa9197,
    30: #f3636e,
    40: #ff3348,
    50: #ff002a,
    60: #e40025,
    70: #dc0024,
    80: #d8001d,
    90: #c80010,
    light: #ff5c66,
  ),
  yellow: (
    5: #feffe7,
    10: #fbfdc1,
    20: #f7fa95,
    30: #f2f765,
    40: #fbff48,
    50: #f7fa06,
    60: #fcea00,
    70: #ffd100,
    80: #ffb700,
    90: #ff8c00
  ),
  accessibilitygreen: (
    5: #E7F4E4,
    90: #2E8540
  ),
  status: (
    error: #F05220
  ),
  icon: (
    default: #212529,
    gray: #EAEAEA
  ),
  line: (
    1: #EBEBEE,
    2: #DADDE1,
  ),
  nav: (
    inactive: #656e7b,
  ),
  brand: (
    primary: #1dbf73,
  ),
  text: (
    1: #55596D,
    2: #ACACAC
  )
);

@function color($color, $tone: 0) {
  @if map-has-key($palettes, $color) {
    $color: map-get($palettes, $color);

    @if map-has-key($color, $tone) {
      $tone: map-get($color, $tone);
      @return $tone;
    }

    @warn "unknown tone `#{$tone}` in color";
    @return null;
  }

  @warn "unknown color `#{$color}` in palette";
  @return null;
}
