@import '../../../../styles/constants/base_constants';
@import '../../../../styles/constants/breakpoints';
@import '../RichText-mixins.scss';

.richtext-render {
  padding: 0 !important;
  background: none !important;
  .DraftEditor-root {
    background: none !important;
  }
  .public-DraftEditor-content {
    min-height: 0 !important;
  }
}

.RichEditor-root {
  background-color: color(white, 0);
  padding: 14px 12px;
  @include eds-rich-text-fonts;
  @include eds-rich-text-code;

  ul,
  ol {
    margin: 16px;

    [dir="rtl"] & {
      padding-right: 20px;
      padding-left: 0;
    }
  }

  .RichEditor-pre {
    @include eds-rich-text-pre;
  }

  .RichEditor-H1 span,
  .RichEditor-H2 span{
    line-height: 1.2;
  } 

  .public-DraftEditor-content {
    min-height: 100px;
    .public-DraftStyleDefault-pre {
      margin-bottom: 0;
    }

    [dir="rtl"] &>div[data-contents="true"] {
      .public-DraftStyleDefault-ltr {
        direction: rtl;
        text-align: start;
      }

      .public-DraftStyleDefault-listLTR {
        direction: rtl;
      }

      .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
        margin-left: 0 !important;
      }

      .public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
        margin-right: 1.5em;
        margin-left: 0;
      }
    }
  }

  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
}

.RichEditor-border {
  border-radius: 0.2em;
}

.DraftEditor-root {
  background: color(white);
}

.RichEditor-root-hashtag-item {
  display: flex;
  &:hover, &:focus {
  }
  &:active {
  }
  .RichEditor-root-hashtag-item-name {
    flex: 1;
    color: color(gray, 80);
  }
  .RichEditor-root-hashtag-item-number {
    color: color(gray, 30);
  }
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  max-height: 350px;
  overflow-y: scroll;
  width: 300px;
}
.draftJsMentionPlugin__mention__29BEd {
  color: inherit;
  background: inherit;
  cursor: default;
  &:hover, &:focus, &:active {
    color: inherit;
    background: inherit;
    cursor: default;
  }
}

body[ dir='rtl'] .public-DraftStyleDefault-listLTR:before {
  right: -36px;
  left: auto;
  text-align: left;
}
