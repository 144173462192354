@import '../../../styles/constants/colors';


$base-margin: 16px;

@mixin eds-rich-text-base-styling {
  h1, h2, h3, h4, h5, h6, a, p, pre, code, blockquote, ol, ul, li {
    // reset all styles to initial to avoid being overwritten by component styles
    all: initial;

    direction: inherit !important;
    font: .875rem  Poppins, PreferredFont, Helvetica, Arial, sans-serif !important;
    color: color(gray, 90) !important;
    word-wrap: break-word !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    white-space: pre-wrap !important;
  }
}

@mixin eds-rich-text-fonts {
  h1, h2, h3, h4, h5, h6 {
    display: block !important;
    margin-bottom: 12px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;

    a {
      font-weight: 600 !important;
    }
  }

  h1,
  h1 a {
    font-size: 1.6rem !important;
  }

  h2,
  h2 a {
    font-size: 1.4rem !important;
  }

  h3,
  h3 a {
    font-size: 1.2rem !important;
  }

  p {
    display: block !important;
    margin-bottom: $base-margin !important;
  }

  a {
    cursor: pointer !important;
    color: color(purple, 'vibrant') !important;
  }
}

@mixin eds-rich-text-list {
  ul, ol {
    display: block;

    li {
      display: list-item;
    }
  }

  > ul {
    margin: $base-margin !important;
  }

  ul {
    padding-left: 20px !important;
    [dir="rtl"] & {
      padding: 0 20px 0 0 !important;
    }
    li {
      list-style-type: disc !important;
      > ul > li {
        list-style-type: circle !important;
      }
    }
  }


  > ol {
    margin: $base-margin 0 !important;
  }

  ol {
    padding-left: 36px !important;
    [dir="rtl"] & {
      padding: 0 36px 0 0 !important;
    }

    li {
      list-style-type: decimal !important;
      padding-left: 6px !important;
      [dir="rtl"] & {
        padding: 0 6px 0 0 !important;
      }
    }

    // level two
    ol {
      padding-left: 20px !important;
      [dir="rtl"] & {
        padding: 0 20px 0 0 !important;
      }

      li {
        list-style-type: lower-alpha !important;
      }
    }
  }
}

@mixin eds-rich-text-code {
  code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    font-size: 90% !important;
    word-break: break-word !important;
  }
}

@mixin eds-rich-text-pre {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    font-size: 90% !important;
    display: block !important;
    font-size: 90% !important;
    color: #212529 !important;
    border: 1px solid #DDD !important;
    background: #EEE !important;
    padding: 8px !important;
    border-radius: 5px !important;
    margin: $base-margin 0 !important;

    code {
      color: inherit !important;
      word-break: normal !important;
      white-space: pre-wrap !important;
    }
}
