@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.App.is_mobile {
  align-items: flex-start;
  background-image: none;
}

/* body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  text-align: start !important;
} */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.scrollbar-custom {
  &::-webkit-scrollbar{
    height: 5px;
    width: 5px;
    background-color: #F6F5FF;
  }
  &::-webkit-scrollbar-thumb {
    background: #D2D7E0;
  }
}


