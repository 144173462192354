@import '../RichText-mixins.scss';

.EDSRichTextRenderer {
  position: relative;
  &__Markdown {
    white-space: pre-wrap;
    @include eds-rich-text-base-styling;
    @include eds-rich-text-fonts;
    @include eds-rich-text-list;
    @include eds-rich-text-code;
    pre {
      @include eds-rich-text-pre;
    }
  }

  &__Latex {
    margin: 10px;
  }

  &__mention {
    padding: 4px 8px;
    color: #1c1e27;
    background-color: #ebebef;
    border-radius: 15px;
    font-weight: 500;
  }

  &__overflow-overlay {
      position: absolute;
      bottom: 35px;
      width: 100%;
      height: 8px;
      background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,255) 125%);

      &--link {
        bottom: 28px;
      }
    }

  &__overflow-btn {
    display: flex;
    justify-content: flex-end;

    a {
      margin-top: .5rem;
    }
  }
}
