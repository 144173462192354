@import '../../../../styles/constants/base_constants';
@import '../../../../styles/constants/breakpoints';

// When toolbar is at the bottom
.RichEditor-editor + .RichEditor-toolbar {
  margin-top: 1rem;
  margin-bottom: 0;
}

.RichEditor-toolbar {
  margin-bottom: 1rem;

  .dropdown {
    h1, h2, h3, p {
      margin: 0 !important;
    }
  }

  svg>path {
    fill: #3E4253;
  }

  .RichEditor-styleButton,
  .RichEditor-headerButton {
    padding: 4px;
    border-radius: 4px;
    border: none;
    background-color: color(white, 0);
    color: color(gray, 40);
    display: inline-block;
    margin-right: 4px;

    [dir="rtl"] & {
      margin-left: 10px;
      margin-right: 0;
    }

    &:disabled {
      background-color: color(white, 0);
      cursor: not-allowed;

      svg > path {
        fill: color(gray, 7);
      }
    }
  }

  .RichEditor-activeButton {
    color: color(gray, 70);
    background-color: color(gray, 7);

    svg>path {
      fill: #3E4253;
    }
  }

  .split-item{
    color: color(gray,10);
  }

}

.align-right {
  .RichEditor-styleButton,
  .RichEditor-headerButton {
    margin-right: 0;
    margin-left: 10px;
  }
}
