@import '../constants/base_constants.scss';

.black-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
}

.black-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid color(gray, 90);
  content: '';
  // animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.black-checkbox {

  .black-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #2277C8;
    border-radius: 6px;
    background-color: color(white);
    // transition: all .3s;
    &.round-check-box-inner {
      border-radius: 50%;
    }
    &:after {
      transform: rotate(45deg) scale(0);
      position: absolute;
      left: (20px - 5px) / 2 - 1.5px * (16 / 14);
      top: (20px - 8px) / 2 - 3px * (16 / 14);
      display: table;
      width: 5px;
      height: 9px;
      border: 2px solid color(purple, vibrant);
      border-top: 0;
      border-left: 0;
      content: ' ';
      // transition: all .1s @ease-in-back, opacity .1s;
      opacity: 0;
    }
  }

  .black-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;

    &.round-check-box-input {
      border-radius: 50%;
    }
  }
}

.black-checkbox-checked .black-checkbox-inner:after {
  transform: rotate(45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid color(white);
  border-top: 0;
  border-left: 0;
  content: ' ';
  // transition: all .2s @ease-out-back .1s;
  opacity: 1;
}

.black-checkbox-checked {
  .black-checkbox-inner {
    background-color: #2277C8;
    border-color:  #2277C8;
  }
}

.black-checkbox-disabled {
  input.black-checkbox-input {
    cursor:not-allowed;
  }

  .black-checkbox-inner {
    border: 2px solid color(gray, 20) !important;
  }
}

.black-checkbox-indeterminate {
  .black-checkbox-inner {
    background-color: color(white);
    border-color: color(purple, vibrant);;
  }

  .black-checkbox-inner:after {
    top: 50%;
    left: 50%;
    width: 7px;
    height: 2px;
    background-color: color(black);
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }
}

.black-checkbox-container {
  line-height: unset;
  cursor: pointer;
  display: flex;
}

.black-checkbox-container + span,
.black-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.black-checkbox-label {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: color(blue, 1);
}
