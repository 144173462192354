@import '../../../../styles/constants/base_constants';
@import '../../../../styles/constants/breakpoints';

.rich-add-link-modal {
  .modal-body{
    label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      color: color(blue, dark);
      margin-bottom: 8px
    }
    input {
      border-radius: 10px;
      padding: 1rem;
      height: auto;
    }
  }

}