@import '../../../../styles/constants/base_constants';
@import '../../../../styles/constants/breakpoints';
@import '../RichText-mixins.scss';

.RichEditor-link-tooltip {
  .tooltip-inner {
    max-width: none;
  }

  .RichEditor-url {
    cursor: pointer;
    color: color(gray, 7);
    max-width: 100px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .RichEditor-split {
    color: color(gray, 40);
    margin: 0 5px;
  }

  .RichEditor-btn {
    cursor: pointer;
  }
}
