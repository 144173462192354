@import 'bootstrap/scss/bootstrap.scss';
@import 'styles/custom-bootstrap.scss';
@import './styles/constants/base_constants';

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 800;
  src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 300;
  src: url('./fonts/Gotham/GothamThin.otf') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 400;
  src: url('./fonts/Gotham/GothamLight.otf') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 500;
  src: url('./fonts/Gotham/GothamSSm-Medium_Web.woff2') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 600;
  src: url('./fonts/Gotham/GothamBold.otf') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 800;
  src: url('./fonts/Gotham/GothamBlack.otf') format('truetype');
}

@font-face {
  font-family: Gotham;
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/Gotham/GothamBookItalic.otf') format('truetype');
}

@font-face {
  font-family: Playfair;
  src: url('./fonts/Playfair/PlayfairDisplay-VariableFont_wght.woff2') format('truetype');
}

@font-face {
  font-family: Playfair;
  font-style: italic;
  src: url('./fonts/Playfair/PlayfairDisplay-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 300;
  src: url('./fonts/SourceSansPro/SourceSansPro-Light.ttf');
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 400;
  src: url('./fonts/SourceSansPro/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: SourceSansPro;
  font-style: italic;
  src: url('./fonts/SourceSansPro/SourceSansPro-Italic.ttf');
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 500;
  src: url('./fonts/SourceSansPro/SourceSansPro-Semibold.ttf');
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 600;
  src: url('./fonts/SourceSansPro/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 800;
  src: url('./fonts/SourceSansPro/SourceSansPro-Black.ttf');
}

@font-face {
  font-family: GreatVibes;
  font-weight: 400;
  src: url('./fonts/GreatVibes/GreatVibes-Regular.ttf');
}

@font-face {
  font-family: Lora;
  font-weight: 400;
  src: url('./fonts/Lora/Lora-VariableFont_wght.ttf');
}

@font-face {
  font-family: DavibLibre;
  font-weight: 400;
  src: url('./fonts/DavibLibre/DavidLibre-Regular.ttf');
}

@font-face {
  font-family: DavibLibre;
  font-weight: 600;
  src: url('./fonts/DavibLibre/DavidLibre-Medium.ttf');
}

@font-face {
  font-family: DavibLibre;
  font-weight: 800;
  src: url('./fonts/DavibLibre/DavidLibre-Bold.ttf');
}

@font-face {
  font-family: Woodmart;
  font-weight: 400;
  src: url('./fonts/Woodmart/woodmart-font-1-400.woff2');
}

@font-face {
  font-family: SFNS;
  font-weight: 400;
  src: url('./fonts/SFNS/SFNS_Regular.ttf');
}

@font-face {
  font-family: SFNS;
  font-weight: 500;
  src: url('./fonts/SFNS/SFNS_SemiBold.ttf');
}

@font-face {
  font-family: SFNS;
  font-weight: 600;
  src: url('./fonts/SFNS/SFNS_Bold.ttf');
}

@font-face {
  font-family: 'PreferredFont';
  src: local("Helvetica Neue");
}

body {
  margin: 0;
  font-family: Roboto, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin safeAreaBottom($baseBottom: 0rem) {
  bottom: calc(#{$baseBottom} + constant(safe-area-inset-bottom)) !important;
  bottom: calc(#{$baseBottom} + env(safe-area-inset-bottom)) !important;
}

.btn_safe_bottom {
  @include safeAreaBottom(30px);
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    color: transparent;
    /* Chrome Safari */
  }

  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.hs-tesla-banner {
  background-image: radial-gradient(54.97% 272.54% at 27.36% -134.72%, rgba(108, 148, 255, .92) 0, rgba(0, 0, 0, .92) 100%);
}

.gotham-font {
  font-family: Gotham, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.playfair-font {
  font-family: Playfair, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.sourceSansPro-font {
  font-family: SourceSansPro, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.greatVibes-font {
  font-family: GreatVibes, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.lora-font {
  font-family: Lora, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.davibLibre-font {
  font-family: DavibLibre, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.woodmart-font {
  font-family: Woodmart, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.SFNS-font {
  font-family: SFNS, PreferredFont, Helvetica, Arial, sans-serif, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.react-tel-input {
  width: 100% !important;
  border: none !important;


  .flag-dropdown {
    width: 80px !important;
    height: 40px !important;
    border: none !important;

    // &:hover {
    //   background-color: #f4f4f4 !important;
    // }

    &.open-dropdown,
    &:hover {
      background-color: #f4f4f4 !important;

      .selected-flag {
        background-color: #f4f4f4 !important;
      }

    }

    .selected-flag {
      padding-left: 20px !important;
      width: 100% !important;
      height: 100%;

      .flag {
        transform: scale(200%);
      }
    }
  }

  .form-control {
    background-color: #f4f4f4 !important;
    border-radius: 5px !important;
    height: 40px !important;
    font-size: 18px;
    border: none !important;
    // border-bottom: 1px solid color(line, 2) !important;
    padding-left: 80px !important;
    box-shadow: none !important;
  }
}

.react-tel-input {
  .um {
    background-position: -112px -143px;
  }

  .sj {
    background-position: -144px -99px;
  }


  .bv {
    background-position: -144px -99px;
  }

  .hm {
    background-position: -192px 0;
  }

  .ax {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Aland.png') !important;
  }

  .aq {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Antarctica_(Graham_Bartram).png') !important;
  }

  .cx {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Christmas_Island.png') !important;
  }

  .cc {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Cocos_(Keeling)_Islands.png') !important;
  }

  .gg {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Guernsey.png') !important;
  }

  .im {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Isle_of_Mann.png') !important;
  }

  .je {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Jersey.png') !important;
  }

  .pn {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Pitcairn_Islands.png') !important;
  }

  .gs {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/South_Georgia_and_the_South_Sandwich_Islands.png') !important;
  }

  .tf {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/French_Southern_and_Antarctic_Lands.png') !important;
  }

  .xk {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Kosovo.png') !important;
  }

  .eh {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Sahrawi_Arab_Democratic_Republic.png') !important;
  }

  .yt {
    background-repeat: no-repeat;
    background-image: url('./images/country_flag/Mayotte_(Local).png') !important;
  }
}

.btn-white {
  color: white !important;
}

.product-img:hover {
  transform: scale(1.1);
  transition: transform 1s ease-in-out;
}

.active-tab {
  border-bottom: 1px solid color(red, light);
  background-color: rgb(235, 235, 235) !important;
}

.drawer-animate {
  animation: dialogAnimate 0.5s;
  -webkit-animation: dialogAnimate 0.5s;
}

@keyframes dialogAnimate {
  0% {
    width: 0%;
    opacity: 0
  }

  100% {
    width: 75%;
    opacity: 1
  }
}

@-webkit-keyframes dialogAnimate {
  0% {
    width: 0%;
    opacity: 0
  }

  100% {
    width: 75%;
    opacity: 1
  }
}

.product-coupon-container {
  background: linear-gradient(90deg, #F8A056, #F93E37);
}

svg.app-coupon-triangle-0 {
  position: absolute;
  top: -78px;
  left: 16px;
  transform: rotateZ(146deg);
}

svg.app-coupon-triangle-1 {
  position: absolute;
  bottom: -66px;
  left: 30%;
  transform: rotateZ(45deg);
}

svg.app-coupon-triangle-2 {
  position: absolute;
  right: 26px;
  top: -82px;
}

.overflow-ellipsis {
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.quantity-icon:hover {
  background-color: #888a8b1a;
}

.quantity-icon.disabled {
  cursor: not-allowed;
}

.shopping-cart-progress-container {
  .progress-bar {
    background-color: #121B17;
  }
}

.react-tel-input.hs-phone-input {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  
  div, input.form-control {
    background-color: white !important;
  }
}


.cart-info-model-show {
  display: block !important;
  animation-duration: 0.5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    height: 0;
  }

  to {
    height: 80%;
  }
}

.active-router  {
  background-color: rgb(235, 235, 235) !important;
}

.hs-icon {
  &::before {
    font-family: 'Woodmart';
    font-size: 100%;
  }

  &.gridview-2 {
    &::before {
      content: "\e901";
    }
  }

  &.gridview-3 {
    &::before {
      content: "\e902";
    }
  }

  &.gridview-4 {
    &::before {
      content: "\e903";
    }
  }
}


.overflow-y-hidden {
  overflow-y: hidden;
}

.hs-homepage-banner .swiper-wrapper {
  height: auto !important;
}

.hs-product-description {
  width: 100%;
  word-break: break-all;
  font-family: Roboto;
  p {
    font-size: 16px ;
  }

  h1 {
    // color: red;
    font-size: 38px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 3px;
    
  }

  a {
    color: color(blue, 40);
  }
  p {
    width: 100%;
    
    img {
      max-width: 100%;
      
      margin: 0 auto;
    }
  }
}

.product-comment-container {
  width: 100%;
  word-break: break-all;
  img {
    max-height: 300px;
  }
}

@media (min-width: 320px) and (max-width: 480px) { 
  .hs-story-description,
  .hs-product-description {
    h1 {
      font-size: 22px;
      line-height: 40px;
  
    }
  }
}

.hs-dialog-online-status::after {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  bottom: -8px;
  left: -5px;
  border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}


.hs-homepage-banner {
  .swiper-slide {
    font-size: 0;
  }
}
